
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  box-sizing: border-box;
  margin: 0;
  padding:0;
  position: relative;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, popins, -apple-system, -apple-system,   'Segoe UI', Roboto, Oxygen,
    Ubuntu, 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #192E28;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
